module.exports = {
  search_course: 'Kurs suchen',
  currently_no_courses_visible: 'Hier gibt es aktuell keine Kurse.',
  courses_todo: 'Ausstehende Kurse',
  sortLabels: {
    name_asc: 'Alphabetisch (A-Z)',
    name_desc: 'Alphabetisch (Z-A)',
    available_from_desc: 'Datum (neuste zuerst)',
    available_from_asc: 'Datum (älteste zuerst)',
  },
  sortShortLabels: {
    name_asc: 'A-Z',
    name_desc: 'Z-A',
    available_from_desc: 'Neuste',
    available_from_asc: 'Älteste',
  },
  filters: {
    active: 'Aktiv',
    finished: 'Abgeschlossen',
    preview: 'Vorschau',
    mandatory: 'Verpflichtend',
  },
  lessons: 'Lektionen',
  participate: 'Starten',
  continue: 'Fortsetzen',
  details: 'Details',
  finish_course: 'Kurs abschließen',
  go_to_first_new: 'Zum ersten neuen Inhalt',
  could_not_participate: 'Die Kursteilnahme ist leider fehlgeschlagen. Bitte versuche es später erneut.',
  previous_lesson: 'Vorherige Lektion',
  new_contents_are_unfinished: 'Es sind neue Inhalte zu diesem Kurs hinzugekommen. Bitte beende die neuen Inhalte, um den Kurs abzuschließen.',
  next_lesson: 'Nächste Lektion',
  next_new_lesson: 'Nächste neue Lektion',
  next_chapter: 'Kapitel abschließen',
  congratulations: 'Kapitel abgeschlossen!',
  congratulationsCertificate: 'Glückwunsch!',
  all_chapter_contents_done: 'Du hast alle Lektionen im Kapitel "%{title}" abgeschlossen.',
  continue_with_chapter: 'Weiter mit Kapitel "%{title}"',
  congratulations_course_done: 'Herzlichen Glückwunsch!',
  course_done: 'Du hast den Kurs "%{title}" erfolgreich bestanden!',
  x_of_y_lessons_done: '%{x} von %{y} Lektionen',
  received_certificate: 'Du hast ein neues Zertifikat erworben!',
  earned_certificate: 'Zertifikat erworben',
  content_started: 'Gestartet',
  content_failed: 'Nicht bestanden',
  content_passed: 'Bestanden',
  content_unfinished: 'Offen',
  didnt_earn_certificate_yet: 'Noch nicht erworben',
  received_certificate_x: 'Zertifikat "%{x}" erworben',
  download_certificate_now: 'Zertifikat jetzt herunterladen',
  has_certificate: 'Mit Zertifikat',
  back_to_course_overview: 'Zurück zur Kursübersicht',
  look_at_questions: 'Fragen noch einmal ansehen',
  answer_questions_now: 'Fragen beantworten',
  go_to_course: 'Zum Kurs',
  preview_label: 'Vorschau',
  preview_btn: 'Vorschau',
  preview_info: 'Die Teilnahme an diesem Kurs ist derzeit nicht möglich. Bei Interesse kannst du über diesen Link die Freigabe des Kurses bei deinem %{appname} Team beantragen.',
  request_access: 'Zugriff anfragen',
  access_request_succeeded: 'Wir haben deine Anfrage erhalten und bearbeiten sie schnellstmöglich.',
  access_request_failed: 'Die Anfrage war leider nicht erfolgreich.',
  no_chapters: 'Dieser Kurs hat derzeit keine sichtbaren Inhalte.',
  no_chapters_email_contact: 'Bitte wende dich an: %{email}',
  admin_preview: {
    close: 'Vorschau schließen',
    reason: {
      archived: 'Der Kurs „%{course}“ ist archiviert.',
      invisible: 'Der Kurs „%{course}“ ist nicht sichtbar.',
      not_available_anymore: 'Der Kurs „%{course}“ ist nicht mehr verfügbar.',
      not_yet_available: 'Der Kurs „%{course}“ ist noch nicht verfügbar.',
      template: 'Du betrachtest die Vorlage „%{course}“.',
    },
  },
  mandatory_course: 'Pflichtkurs',
  tasks: 'Aufgaben',
  learning_duration: 'Lernzeit',
  show_results: 'Ergebnisse anzeigen',
  answered_x_of_y_questions_correctly: 'Du hast {correct} von einer Frage richtig beantwortet.|Du hast {correct} von {total} Fragen richtig beantwortet.',
  answered_all_preview_questions: 'Alle Fragen in der Vorschau beantwortet.',
  preview_doesnt_persist_results: 'In der Vorschau werden Ergebnisse nicht erfasst.',
  endOfPreview: 'Dies ist das Ende der Kursvorschau. In der Vorschau werden Ergebnisse nicht erfasst, du kannst die Vorschau also jederzeit neu starten.',
  restartPreview: 'Vorschau neu starten',
  courseContentInvisible: 'Dieser Inhalt ist noch nicht veröffentlicht und ist für User nicht sichtbar.',
  backToOverview: 'Zur Kursübersicht',
  lessonsXofY: '{passed} / {total} Lektionen',
  lessonsPassed: 'abgeschlossen',
  noRelatedData: {
    appointment: 'Diesem Inhalt ist kein Termin zugewiesen.',
    certificate: 'Diesem Inhalt ist kein Zertifikat zugewiesen.',
    form: 'Diesem Inhalt ist kein Formular zugewiesen.',
    questions: 'Diesem Inhalt sind keine Fragen zugewiesen.',
    learningMaterial: 'Diesem Inhalt ist keine Datei zugewiesen.',
    todoList: 'Diesem Inhalt sind keine Aufgaben zugewiesen.',
  },
  navigationTutorialMobile: 'Swipe nach rechts oder links um durch die Lektionen zu navigieren.',
  navigationTutorialDesktop: 'Klicke auf die Vor- und Zurück-Icons oder scrolle, um durch die Lektionen zu navigieren.',
  reset_participation_progress: 'Kursfortschritt zurücksetzen',
  reset_participation_progress_confirm: 'Bist du sicher, dass du deinen Fortschritt für diesen Kurs dauerhaft zurücksetzen möchtest?',
  confirm_reset_participation_progress: 'Ja, meinen gesamten Fortschritt löschen',
  reset_course_progress_successful: 'Dein Fortschritt wurde erfolgreich zurückgesetzt! Du kannst jetzt wieder teilnehmen.',
  reset_course_progress_failed: 'Das Zurücksetzen von deinem Kursfortschritt hat nicht geklappt!',
  almostDone: {
    title: 'Fast geschafft!',
    needToFinishContentsFirst: 'Bevor du den Kurs bestanden hast, musst du noch ein paar Inhalte abschließen.',
    followingContentsUnfinished: 'Folgende Inhalte sind noch offen:',
    goToFirstUnfinishedContent: 'Zum ersten offenen Kursinhalt',
  },
  contentsList: {
    lessons: 'Lektionen',
    courseEnd: 'Kursende',
    content_unfinished: 'Offen',
  },
  certificates: {
    unfinishedCertificateTitle: 'Zertifikat',
    unfinishedCertificateDescription: 'Um dieses Zertifikat zu erhalten, musst du zuerst die vorherigen Kursinhalte abschließen.',
  },
}
